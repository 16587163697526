import { commonApi, TimeSlot, UserCalendarEventDetailsDto } from '@bd/api'
import { getMeetingOffersByAddress } from '@bd/api/common/calendar-event-api'
import {
  MeetingAttendeeSearchParams,
  MeetingOfferSearchParams,
} from '@bd/api/common/types/calendar-event'
import { LocalIsoDate, logErr } from '@bd/helpers'
import { CommonAppState } from '@bd/store-modules/types'
import {
  CalendarEventMeetingType,
  CommonCalendarEventState,
  EventBuyerSelectPayload,
  EventDateSelectPayload,
  MeetingAttendeeSearchPayload,
  MeetingOfferSearchPayload,
  MeetingOfferSelectPayload,
  OfferSelectionTab,
} from '@bd/store-modules/types/calendarEvent'
import { ActionTree } from 'vuex'
import {
  getAttendeesSearchPhrase,
  getOffersSearchPhrase,
  getSelectedOffersTab,
} from './getters-helpers'
import {
  getDisabledTimeSlots,
  getEventSaveMethod,
  isAnySelectedSlotDisabled,
  mapEventDetailsToSaveData,
  prevalidateAvailableTimeSlotsMethod,
} from './utils'

export const actions: ActionTree<CommonCalendarEventState, CommonAppState> = {
  setOfferSelectedTab({ commit }, selectedTab: OfferSelectionTab) {
    commit('SET_OFFER_SELECTED_TAB', selectedTab)
  },
  setOffersSearchPhrase({ commit }, searchPhrase: string) {
    commit('SET_OFFERS_SEARCH_PHRASE', searchPhrase)
  },
  setAttendeesSearchPhrase({ commit }, searchPhrase: string) {
    commit('SET_ATTENDEES_SEARCH_PHRASE', searchPhrase)
  },

  async getMeetingOffers({ commit }, payload: MeetingOfferSearchPayload) {
    const searchPhrase = getOffersSearchPhrase(this)
    const params: MeetingOfferSearchParams = {
      address: searchPhrase,
      all: payload.isAgent
        ? getSelectedOffersTab(this) === OfferSelectionTab.All
        : true,
      size: payload.pageSize,
      showDrafts: true
    }

    commit('SET_MEETING_OFFERS_LOADING', true)
    try {
      const { data } = await getMeetingOffersByAddress(params)
      commit('SET_MEETING_OFFERS', data)
    } finally {
      commit('SET_MEETING_OFFERS_LOADING', false)
    }
  },

  clearEventSaveData({ commit }) {
    commit('CLEAR_EVENT_SAVE_DATA')
  },

  async setEventSaveInitialData(
    { rootState },
    payload: UserCalendarEventDetailsDto,
  ) {
    const eventAgent = payload.calendarEventDto.agent
    if (eventAgent) {
      this.dispatch('calendarEvent/setSelectedAgentId', eventAgent)
    }
    const initialData = mapEventDetailsToSaveData(rootState, payload)
    await Promise.all([
      this.dispatch(
        'calendarEvent/setUserCalendarEventId',
        initialData.eventId,
      ),
      this.dispatch('calendarEvent/setSelectedOfferAndSeller', {
        offer: initialData.offer,
        seller: initialData.seller,
      }),
      this.dispatch('calendarEvent/setSelectedDate', {
        date: initialData.date,
      }),
      this.dispatch('calendarEvent/setSelectedBuyer', {
        buyer: initialData.buyer,
      }),
      this.dispatch(
        'calendarEvent/setSelectedMeetingType',
        initialData.meetingType,
      ),
      this.dispatch('calendarEvent/getMeetingAvailableTimeSlots'),
    ])
    this.dispatch('calendarEvent/setSelectedTimeSlots', initialData.timeSlotIds)
  },

  setSelectedOfferAndSeller({ commit }, payload: MeetingOfferSelectPayload) {
    commit('SET_SELECTED_OFFER', payload.offer)
    commit('SET_SELECTED_SELLER', payload.seller)
  },

  async getMeetingAttendees({ commit }, payload: MeetingAttendeeSearchPayload) {
    const searchPhrase = getAttendeesSearchPhrase(this)
    const params: MeetingAttendeeSearchParams = {
      query: searchPhrase,
      size: payload.pageSize,
      userRole: payload.userRole,
    }

    commit('SET_MEETING_ATTENDEES_LOADING', true)
    try {
      const { data } = await commonApi.getMeetingAttendeesByQuery(params)
      commit('SET_MEETING_ATTENDEES', data)
    } finally {
      commit('SET_MEETING_ATTENDEES_LOADING', false)
    }
  },

  setSelectedBuyer({ commit }, payload: EventBuyerSelectPayload) {
    commit('SET_SELECTED_BUYER', payload)
  },
  setSelectedAgentId({ commit }, payload: number) {
    commit('SET_SELECTED_AGENT_ID', payload)
  },

  setSelectedMeetingType({ commit }, meetingType: CalendarEventMeetingType) {
    commit('SET_SELECTED_MEETING_TYPE', meetingType)
    commit('SET_SELECTED_TIME_SLOTS', [])
  },

  clearBuyerRequired({ state, commit }) {
    const currentlySelectedMeetingType = state.eventSaveData.meetingType
    if (!currentlySelectedMeetingType) {
      return
    }
    const newMeetingType: CalendarEventMeetingType = {
      ...currentlySelectedMeetingType,
      customerPresenceRequired: false,
    }
    commit('SET_SELECTED_MEETING_TYPE', newMeetingType)
  },

  setSelectedDate({ commit }, payload: EventDateSelectPayload) {
    commit('SET_SELECTED_DATE', payload)
  },

  setSelectedTimeSlots({ commit }, timeSlotIds: TimeSlot.TimeSlotID) {
    commit('SET_SELECTED_TIME_SLOTS', timeSlotIds)
  },

  async getMeetingAvailableTimeSlots({ rootState, commit }) {
    const fetchTimeSlots = prevalidateAvailableTimeSlotsMethod(rootState)
    if (!fetchTimeSlots) {
      return
    }

    commit('SET_AVAILABLE_TIME_SLOTS_LOADING', true)
    try {
      const { data } = await fetchTimeSlots()
      commit('SET_AVAILABLE_TIME_SLOTS', data)

      const disabledTimeSlots = getDisabledTimeSlots(rootState)
      commit('SET_DISABLED_TIME_SLOTS', disabledTimeSlots)

      if (isAnySelectedSlotDisabled(rootState)) {
        commit('SET_SELECTED_TIME_SLOTS', [])
      }
    } finally {
      commit('SET_AVAILABLE_TIME_SLOTS_LOADING', false)
    }
  },

  setUserCalendarEventId({ commit }, id: number) {
    commit('SET_USER_CALENDAR_EVENT_ID', id)
  },

  async saveEvent({ rootState }) {
    const method = getEventSaveMethod(rootState)
    if (!method) {
      return
    }
    try {
      const {
        data: { value },
      } = await method()
      return value
    } catch (err) {
      logErr(err)
      throw err
    }
  },
}
