import { isLoggedGuard, isNotLoggedGuard } from '@bd/components'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AppLayout from '../components/AppLayout.vue'
import store from '../store'
import {
  calendarEventGuard,
  calendarRootResolver,
} from './guards/calendar-guard'

const calendarEventRoutes: Array<RouteRecordRaw> = [
  {
    path: 'event',
    name: 'CalendarEvent',
    redirect: '/calendar',
    beforeEnter: calendarEventGuard(store),
    component: () =>
      import(
        /* webpackChunkName: "CalendarEvent" */ '../views/Calendar/CalendarEvent/CalendarEventRouterView.vue'
      ),
    children: [
      {
        path: ':id',
        name: 'CalendarEventDetails',
        component: () =>
          import(
            /* webpackChunkName: "CalendarEventDetails" */ '../views/Calendar/CalendarEvent/CalendarEventDetails.vue'
          ),
      },
      {
        path: 'add',
        name: 'CalendarEventNew',
        component: () =>
          import(
            /* webpackChunkName: "CalendarEventSave" */ '../views/Calendar/CalendarEvent/CalendarEventSave.vue'
          ),
      },
      {
        path: ':id/edit',
        name: 'CalendarEventEdit',
        component: () =>
          import(
            /* webpackChunkName: "CalendarEventSave" */ '../views/Calendar/CalendarEvent/CalendarEventSave.vue'
          ),
      },
    ],
  },
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: AppLayout,
    redirect: '/dashboard',
    beforeEnter: isLoggedGuard(store),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ '../views/Dashboard/Dashboard.vue'
          ),
      },
      {
        path: '/offer/:offerId/statistics',
        name: 'Statistics',
        component: () =>
          import(
            /* webpackChunkName: "Statistics" */ '../views/Statistics/Statistics.vue'
          ),
      },
      {
        path: '/offer/:id',
        name: 'DashboardOffer',
        component: () =>
          import(
            /* webpackChunkName: "DashboardOffer" */ '../views/Dashboard/DashboardOffer.vue'
          ),
      },
      {
        path: '/calendar',
        beforeEnter: calendarRootResolver(store),
        component: () =>
          import(
            /* webpackChunkName: "Calendar" */ '../views/Calendar/CalendarRouterView.vue'
          ),
        children: [
          {
            path: '',
            name: 'Calendar',
            component: () =>
              import(
                /* webpackChunkName: "Calendar" */ '../views/Calendar/Calendar.vue'
              ),
          },
          ...calendarEventRoutes,
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: () =>
          import(
            /* webpackChunkName: "Notifications" */ '../views/Notifications/Notifications.vue'
          ),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ '../views/Profile/Profile.vue'
          ),
      },
      {
        path: '/profile/transactions',
        name: 'ProfileTransactions',
        component: () =>
          import(
            /* webpackChunkName: "ProfileTransactions" */ '../views/Profile/ProfileTransactions.vue'
          ),
      },
      {
        path: '/client/add',
        name: 'AddClient',
        component: () =>
          import(
            /* webpackChunkName: "Client" */ '../views/Client/AddClient.vue'
          ),
      },
      {
        path: '/client/:id',
        name: 'ClientDetails',
        beforeEnter: async (to, _from, next) => {
          await store.dispatch('clients/clientDetails', to.params.id)
          next()
        },
        component: () =>
          import(
            /* webpackChunkName: "ClientDetails" */ '../views/Client/ClientDetails.vue'
          ),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ '../views/PasswordReset.vue'
      ),
  },
  {
    path: '/password-reset-confirmation',
    name: 'PasswordResetConfirmation',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(
        /* webpackChunkName: "PasswordResetConfirmation" */ '../views/PasswordResetConfirmation.vue'
      ),
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROOT_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const dialog = store.state.dialog

  if (dialog.counterProposalModal || dialog.proposalModal) {
    store.dispatch('dialogVisibility', {
      counterProposalModal: false,
      proposalModal: false,
    })
  } else {
    next()
  }
})

export default router
