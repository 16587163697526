import { AgentOfferState, AppState } from '@bd/agent/types'
import { ActionTree } from 'vuex'
import { Filters, FiltersState } from '@bd/store-modules/types'
import { agentApi, commonApi, PageableParams } from '@bd/api'
import { logErr } from '@bd/helpers'
import {
  allCitiesLoaded,
  getNextPageIndex,
  isLoadingChunk,
} from './gettersHelper'

export const actions: ActionTree<AgentOfferState, AppState> = {
  async citiesAndProvincesList({ state, commit }, pageSize: number) {
    if (isLoadingChunk(state) || allCitiesLoaded(state)) {
      return
    }
    const params: PageableParams = {
      pageSize: pageSize,
      pageIndex: getNextPageIndex(state),
    }

    commit('SET_CITIES_AND_PROVINCES_LOADING', true)
    try {
      const { data } = await commonApi.citiesAndProvincesUsed(params)
      commit('ADD_CITIES_AND_PROVINCES_CHUNK', data)
    } finally {
      commit('SET_CITIES_AND_PROVINCES_LOADING', false)
    }
  },

  async cityDistricts({ commit }, payload: number) {
    try {
      const { data } = await commonApi.districts(payload)
      commit('SET_DISTRICTS', data)
    } catch (error) {
      logErr(error)
    }
  },

  async fetchOffer({ commit }, payload: number) {
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await agentApi.offer(payload)
      commit('SET_OFFER', data)
    } catch (error) {
      logErr(error)
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },

  async setFilters({ commit }, payload: Filters) {
    commit('SET_FILTERS', payload)
  },

  async setFilterAndRefresh({ commit }, payload: Filters) {
    commit('SET_FILTERS', payload)
    commit('APPLY_FILTERS')
  },

  async clearFilterAndRefresh(
    { commit },
    payload?: { keep: (keyof FiltersState)[] },
  ) {
    commit('CLEAR_FILTERS', payload?.keep)
    commit('APPLY_FILTERS')
  },
}
