import { Module } from 'vuex'
import { AgentPurchaseState, AppState } from '@bd/agent/types'

import { state } from './state'
import { actions } from './actions'
import { mutations } from './mutations'

const purchaseOffers: Module<AgentPurchaseState, AppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default purchaseOffers
