import { useAppStore } from '@bd/store-modules'
import {
  CalendarEventMessages,
  ConfirmationDialogOptions,
  DialogOptions,
  EventActionType,
} from '@bd/components'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'

export interface ConfirmationDialogState {
  visible: boolean
  options?: DialogOptions
}

export const useCalendarEventActions = () => {
  const store = useAppStore()
  const router = useRouter()
  const toast = useToast()
  const eventDetails = computed(() => store.state.calendar?.eventDetails)
  const eventDetailsContent = computed(() => eventDetails.value?.content)

  const submitPending = ref(false)

  const confirmationDialogState = ref<ConfirmationDialogState>({
    visible: false,
  })

  const showConfirmationDialog = (actionType: EventActionType) => {
    confirmationDialogState.value = {
      visible: true,
      options: ConfirmationDialogOptions[actionType],
    }
  }

  const closeConfirmationDialog = () => {
    confirmationDialogState.value.visible = false
  }

  const performConfirmationRequiredAction = async (
    action: () => Promise<void>,
    errorHandler: (err: unknown) => void,
    useDefaultSuccessHandler = true,
  ) => {
    submitPending.value = true
    try {
      await action()
      if (useDefaultSuccessHandler) {
        closeConfirmationDialog()
        router.push({ name: 'Calendar' })
      }
    } catch (err) {
      errorHandler(err)
    } finally {
      submitPending.value = false
    }
  }

  const acceptCalendarEvent = async () => {
    const eventId = eventDetailsContent.value?.userCalendarEventId
    performConfirmationRequiredAction(
      async () => {
        await store.dispatch('calendar/acceptCalendarEvent', eventId)
        toast.add(CalendarEventMessages.acceptSuccess)
        closeConfirmationDialog()
        store.dispatch('calendar/userCalendarEventDetails', eventId)
      },
      (_) => {
        toast.add(CalendarEventMessages.acceptError)
      },
      false,
    )
  }

  const cancelCalendarEvent = async () => {
    performConfirmationRequiredAction(
      async () => {
        await store.dispatch(
          'calendar/cancelCalendarEvent',
          eventDetailsContent.value?.userCalendarEventId,
        )
        toast.add(CalendarEventMessages.cancelSuccess)
      },
      (_) => {
        toast.add(CalendarEventMessages.cancelError)
      },
    )
  }

  const rejectCalendarEvent = () => {
    performConfirmationRequiredAction(
      async () => {
        await store.dispatch(
          'calendar/rejectCalendarEvent',
          eventDetailsContent.value?.userCalendarEventId,
        )
        toast.add(CalendarEventMessages.rejectSuccess)
      },
      (_) => {
        toast.add(CalendarEventMessages.rejectError)
      },
    )
  }

  const onDialogConfirm = () => {
    const actionType = confirmationDialogState.value.options?.type
    if (actionType === EventActionType.Cancel) {
      cancelCalendarEvent()
    } else if (actionType === EventActionType.Reject) {
      rejectCalendarEvent()
    } else if (actionType === EventActionType.Accept) {
      acceptCalendarEvent()
    }
  }

  const onDialogReject = () => {
    confirmationDialogState.value.visible = false
  }

  const onEventAccept = () => {
    showConfirmationDialog(EventActionType.Accept)
  }
  const onEventCancel = () => {
    showConfirmationDialog(EventActionType.Cancel)
  }
  const onEventReject = () => {
    showConfirmationDialog(EventActionType.Reject)
  }
  const onEventEdit = async () => {
    const eventId = eventDetailsContent.value!.userCalendarEventId

    // Refetch event details to ensure the freshest possible data
    await store.dispatch('calendar/userCalendarEventDetails', eventId)

    router.push({
      name: 'CalendarEventEdit',
      params: { id: eventId },
    })
  }

  return {
    eventDetails,
    eventDetailsContent,
    confirmationDialogState,
    submitPending,
    onDialogConfirm,
    onDialogReject,
    onEventAccept,
    onEventCancel,
    onEventReject,
    onEventEdit,
  }
}
