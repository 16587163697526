<template>
  <ProposalModal
    :header="t('proposal.make_proposal')"
    :priceTitle="t('proposal.buyer_price_proposal')"
    :isInputDisabled="false"
    @hide="onHide"
    v-model="priceInputValue"
  >
    <template #content>
      <div class="search-wrapper">
        <p class="search-label mb-3">
          {{ t('proposal.buyer_first_and_last_name') }}
        </p>
        <ProposalSearchList
          :searchPhrase="searchPhraseRef"
          :clients="clients"
          :selectedClient="selectedClient"
          @selectClient="onClientSelected"
          @searchClient="fetchClientsByQuery"
          @update:searchPhrase="onSearchPhraseUpdate"
          @clear="selectedClient = undefined"
        >
          <template #empty>
            <EmptyState
              :title="
                t('calendar.events.save_event.attendees.empty_state.title')
              "
              :description="
                t(
                  'calendar.events.save_event.attendees.empty_state.description',
                )
              "
            />
          </template>
        </ProposalSearchList>
      </div>
    </template>
    <template #footer>
      <small class="text-center py-2" v-if="errorMessage">
        {{ errorMessage }}
      </small>
      <Button
        :disabled="!selectedClient || !priceInputValue"
        class="p-button-primary p-button-sm px-4 w-100 p-button-rounded"
        :label="t('proposal.buyer_make_a_proposal')"
        @click="makeProposal"
      />
    </template>
  </ProposalModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed, ComputedRef, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { ResultPageDto, ErrorResponse } from '@bd/api'
import { LoadableOptional } from '@bd/helpers'
import { useAppStore } from '@bd/agent/store'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'
import {
  ProposalUserDto,
  purchaseOfferService,
  ProposalModal,
  ProposalSearchList,
  ProposalMessages,
} from '@bd/components'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  name: 'ProposalBuyerSearch',
  components: {
    ProposalModal,
    ProposalSearchList,
    EmptyState,
  },
  props: {
    errorData: {
      type: Object as PropType<ErrorResponse | undefined>,
      required: false,
    },
  },
  emits: ['searchAttendee', 'update:searchPhrase', 'hide'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const toast = useToast()
    const priceInputValue = ref<null | number>(null)
    const errorMessage = computed(() => {
      return purchaseOfferService.handleError(props.errorData)
    })

    const searchPhraseRef = ref<string>('')
    const selectedClient = ref<ProposalUserDto | undefined>(undefined)

    const clients: ComputedRef<
      LoadableOptional<ResultPageDto<MeetingAttendeeDto> | undefined>
    > = computed(() => ({
      payload: store.state.clients?.clientsByQuery,
    }))

    const fetchClientsByQuery = async () => {
      await store.dispatch('clients/clientByQuery', searchPhraseRef.value)
    }

    const onClientSelected = (selected: ProposalUserDto) => {
      selectedClient.value = selected
    }

    const onSearchPhraseUpdate = (searchPhrase: string) => {
      searchPhraseRef.value = searchPhrase
    }

    const onHide = () => {
      emit('hide')
      priceInputValue.value = null
      selectedClient.value = undefined
      store.commit('purchaseOffers/CLEAR_ERROR')
    }

    const makeProposal = async () => {
      const offerId = route.params.id
      const buyerId = selectedClient.value?.id
      const price = priceInputValue.value
      const type = 'BUYER_TO_SELLER'

      if (!buyerId || !offerId || !price) {
        return
      }

      await store.dispatch('purchaseOffers/addPurchase', {
        offerId,
        buyerId,
        price,
        type,
      })

      if (!errorMessage.value) {
        store.dispatch('dialogVisibility', {
          proposalModal: false,
        })
      }
      toast.add(
        !errorMessage.value
          ? ProposalMessages.counterproposalSuccess
          : ProposalMessages.counterproposalError,
      )
    }

    fetchClientsByQuery()

    return {
      t,
      onClientSelected,
      selectedClient,
      onSearchPhraseUpdate,
      fetchClientsByQuery,
      searchPhraseRef,
      clients,
      makeProposal,
      store,
      onHide,
      priceInputValue,
      errorMessage,
    }
  },
})
</script>

<style lang="scss" scoped>
.search-wrapper {
  padding: 40px 0;
  @include breakpoint-down(md) {
    padding: 10px 40px;
  }
}

.search-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: $black;
}
</style>
