import { AgentOfferState } from '@bd/agent/types'
import { emptyLoadableResource } from '@bd/helpers'

export const state: AgentOfferState = {
  offers: {
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    content: [],
  },
  offerDetails: undefined,
  citiesAndProvinces: emptyLoadableResource(),
  districts: [],
  filters: {
    pageSize: 20,
    showAll: true,
  },
  searchedFilters: {
    pageSize: 20,
    showAll: true,
  },
}
