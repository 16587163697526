<template>
  <TopNavigation :items="navigationItems" isSticky>
    <template #view-left>
      <img src="@bd/assets/images/immoby-horizontal-logo.svg" />
      <QuickSearch class="quick-search" />
    </template>
  </TopNavigation>

  <div
    class="content-container"
    :class="{ 'bg-white': $route.name === 'DashboardOffer' }"
    v-if="online"
  >
    <router-view />
  </div>
  <div class="offline-container" v-show="!online">
    <OfflineScreen />
  </div>
  <NotificationsHost view-type="dropdown" v-if="!isNotificationsRoute" />
  <Toast :baseZIndex="toastZIndex" :position="toastPosition" />
  <BottomNavigation :items="navigationItems" v-if="showBottomNavigation" />
  <ProposalModalHost />
</template>

<script lang="ts">
import { useAppStore } from '@bd/agent/store'
import {
  BottomNavigation,
  NotificationsHost,
  OfflineScreen,
  TopNavigation,
  useBreakpoints,
  useNavigationBadges,
  usePurchaseOffers,
  useToastConfig,
} from '@bd/components'
import { useOnline } from '@vueuse/core'
import Toast from 'primevue/toast'
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { buildNavigation } from '../config'
import ProposalModalHost from './ProposalModalHost.vue'
import QuickSearch from './QuickSearch/QuickSearch.vue'

export default defineComponent({
  name: 'AppLayout',
  components: {
    TopNavigation,
    BottomNavigation,
    Toast,
    NotificationsHost,
    ProposalModalHost,
    QuickSearch,
    OfflineScreen,
  },
  setup() {
    const store = useAppStore()
    const route = useRoute()
    const router = useRouter()
    const { mobileSize } = useBreakpoints()
    const { toastPosition, toastZIndex } = useToastConfig()

    const navigationItems = computed(() =>
      buildNavigation(store, router, mobileSize),
    )
    const showBottomNavigation = computed(() => {
      return store.state.bottomNavigationVisible
    })

    const isNotificationsRoute = computed(() => route.name === 'Notifications')

    const online = useOnline()

    useNavigationBadges()
    usePurchaseOffers()

    return {
      navigationItems,
      showBottomNavigation,
      toastPosition,
      toastZIndex,
      isNotificationsRoute,
      online,
    }
  },
})
</script>

<style lang="scss" scoped>
.content-container {
  width: 100%;
  margin: 0 auto;
  background-color: $default-bg-color;
  overflow-y: auto;
}

.quick-search {
  margin: 0 30px;
  flex: 0 1 30%;

  @include breakpoint-down(xl) {
    flex: 0 1 40%;
  }

  @include breakpoint-down(lg) {
    flex: 1 1 0;
  }
}
.offline-container {
  height: calc(100vh - #{$top-navigation-height});
}
</style>
