import { MutationTree } from 'vuex'
import { AgentOfferState } from '@bd/agent/types'
import { offersPerPage, showDraftsOffers } from '@bd/agent/config/common.json'
import { addOrRemoveFilter } from '@bd/client/services/mutation.service'
import { Filters, FiltersState } from '@bd/store-modules/types'
import { ResultPageDto, CityWithProvinceDto } from '@bd/api'
import { uniqBy } from '@bd/helpers'

export const mutations: MutationTree<AgentOfferState> = {
  SET_CITIES_AND_PROVINCES_LOADING(state, isLoading: boolean) {
    state.citiesAndProvinces.isLoading = isLoading
  },
  SET_DISTRICTS(state, payload) {
    state.districts = payload
  },
  ADD_CITIES_AND_PROVINCES_CHUNK(
    state,
    payload: ResultPageDto<CityWithProvinceDto>,
  ) {
    const currentContent = state.citiesAndProvinces.content
    state.citiesAndProvinces.content = {
      ...payload,
      content: currentContent
        ? uniqBy(
            [...currentContent.content, ...payload.content],
            (x) => x.cityId,
          )
        : payload.content,
    }
  },
  SET_FILTERS(state, payload: Filters) {
    const result = addOrRemoveFilter({ ...state.filters }, payload)
    if (result) {
      state.filters = result
    }
  },
  APPLY_FILTERS(state) {
    state.searchedFilters = state.filters
  },
  CLEAR_FILTERS(state, keep: (keyof FiltersState)[] = []) {
    const keepFilters: Partial<FiltersState> = {}
    keep.forEach((key) => {
      if (key in state.filters) {
        keepFilters[key] = state.filters[key] as never
      }
    })
    const filters: FiltersState = {
      pageSize: offersPerPage,
      showDrafts: showDraftsOffers,
      ...keepFilters,
    }

    state.filters = filters
  },
  SET_SEARCHED_FILTERS(state, payload) {
    state.searchedFilters = payload
  },
  SET_OFFER(state, payload) {
    state.offerDetails = payload
  },
}
