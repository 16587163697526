import { AppState } from '@bd/agent/types'

export const state: AppState = {
  isLoading: false,
  dialog: {
    dashboardFilters: false,
    proposalModal: false,
    counterProposalModal: false,
  },
  bottomNavigationVisible: false,
}
