import { MutationTree } from 'vuex'
import { AgentPurchaseState } from '@bd/agent/types'
import {
  AgentPurchaseOfferDto,
  ProposalOfferMessages,
} from '@bd/api/common/types/purchase-offers'
import { ErrorResponse } from '@bd/api'

export const mutations: MutationTree<AgentPurchaseState> = {
  SET_PURCHASE_MESSAGE(state, payload: AgentPurchaseOfferDto) {
    const isPurchaseAccepted = payload?.accepted && payload.offerId

    const message = {
      message: ProposalOfferMessages.PROPOSAL_ACCEPTED,
      id: payload.offerId,
    }

    if (isPurchaseAccepted) {
      state.purchaseMessage = message
    }
  },
  CLEAR_PURCHASE_MESSAGE(state) {
    state.purchaseMessage = undefined
  },
  SET_PURCHASE(state, payload: AgentPurchaseOfferDto) {
    state.purchaseOffer = payload
  },
  CLEAR_LAST_PURCHASE(state) {
    state.purchaseOffer = undefined
  },
  SET_ERROR(state, payload: ErrorResponse) {
    state.error = payload
  },
  CLEAR_ERROR(state) {
    state.error = undefined
  },
}
