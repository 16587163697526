import { AppStore } from '@bd/agent/store'
import { isAvailabilitySet } from '@bd/agent/store/modules/availability/getters-helpers'
import { NavigationGuardNext, RouteLocationNormalizedLoaded } from 'vue-router'

/**
 * Route guard that aims to protect calendar event routes from entry
 * when the agent doesn't have his availability set yet
 */
export const calendarEventGuard = (store: AppStore) => (
  _to: RouteLocationNormalizedLoaded,
  _from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext,
) => {
  if (isAvailabilitySet(store.state.availability)) {
    next()
  } else {
    next({ path: '/calendar' })
  }
}

/**
 * Route guard that allows to fetch availability rules.
 * It's purpose is to be used as onBeforeEnter handler on the main (root) calendar route
 * in order to load the agent availability before entering that route therefore
 * allowing for this route's children to react to availabilty state being present or not
 */
export const calendarRootResolver = (store: AppStore) => async (
  _to: RouteLocationNormalizedLoaded,
  _from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext,
) => {
  await store.dispatch('availability/availabilityRules')
  next()
}
