
import { defineComponent, ref, computed, ComputedRef, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { ResultPageDto, ErrorResponse } from '@bd/api'
import { LoadableOptional } from '@bd/helpers'
import { useAppStore } from '@bd/agent/store'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'
import {
  ProposalUserDto,
  purchaseOfferService,
  ProposalModal,
  ProposalSearchList,
  ProposalMessages,
} from '@bd/components'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  name: 'ProposalBuyerSearch',
  components: {
    ProposalModal,
    ProposalSearchList,
    EmptyState,
  },
  props: {
    errorData: {
      type: Object as PropType<ErrorResponse | undefined>,
      required: false,
    },
  },
  emits: ['searchAttendee', 'update:searchPhrase', 'hide'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const toast = useToast()
    const priceInputValue = ref<null | number>(null)
    const errorMessage = computed(() => {
      return purchaseOfferService.handleError(props.errorData)
    })

    const searchPhraseRef = ref<string>('')
    const selectedClient = ref<ProposalUserDto | undefined>(undefined)

    const clients: ComputedRef<
      LoadableOptional<ResultPageDto<MeetingAttendeeDto> | undefined>
    > = computed(() => ({
      payload: store.state.clients?.clientsByQuery,
    }))

    const fetchClientsByQuery = async () => {
      await store.dispatch('clients/clientByQuery', searchPhraseRef.value)
    }

    const onClientSelected = (selected: ProposalUserDto) => {
      selectedClient.value = selected
    }

    const onSearchPhraseUpdate = (searchPhrase: string) => {
      searchPhraseRef.value = searchPhrase
    }

    const onHide = () => {
      emit('hide')
      priceInputValue.value = null
      selectedClient.value = undefined
      store.commit('purchaseOffers/CLEAR_ERROR')
    }

    const makeProposal = async () => {
      const offerId = route.params.id
      const buyerId = selectedClient.value?.id
      const price = priceInputValue.value
      const type = 'BUYER_TO_SELLER'

      if (!buyerId || !offerId || !price) {
        return
      }

      await store.dispatch('purchaseOffers/addPurchase', {
        offerId,
        buyerId,
        price,
        type,
      })

      if (!errorMessage.value) {
        store.dispatch('dialogVisibility', {
          proposalModal: false,
        })
      }
      toast.add(
        !errorMessage.value
          ? ProposalMessages.counterproposalSuccess
          : ProposalMessages.counterproposalError,
      )
    }

    fetchClientsByQuery()

    return {
      t,
      onClientSelected,
      selectedClient,
      onSearchPhraseUpdate,
      fetchClientsByQuery,
      searchPhraseRef,
      clients,
      makeProposal,
      store,
      onHide,
      priceInputValue,
      errorMessage,
    }
  },
})
