import { createStore, Store, useStore } from 'vuex'

import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

import {
  auth,
  staticData,
  userProfile,
  transactionHistory,
  calendarEvent,
  notifications,
  calendar,
} from '@bd/store-modules'
import offers from './modules/offers'
import availability from './modules/availability'
import clients from './modules/clients'
import purchaseOffers from './modules/purchase-offers'
import { AppState } from '@bd/agent/types'
import { IS_DEV, vuexLogger } from '@bd/helpers'

export type AppStore = Store<AppState>
export const useAppStore = (): AppStore => useStore<AppState>()

export default createStore({
  strict: IS_DEV,
  actions,
  mutations,
  state,
  modules: {
    auth,
    offers,
    staticData,
    calendar,
    calendarEvent,
    availability,
    userProfile,
    transactionHistory,
    clients,
    notifications,
    purchaseOffers,
  },
  plugins: [vuexLogger('vuex.agent')],
})
