import { AppState, AgentPurchaseState } from '@bd/agent/types'
import { ActionTree } from 'vuex'
import { commonApi } from '@bd/api'
import { logErr } from '@bd/helpers'
import {
  PurchaseOfferPayload,
  AddAgentPurchaseOfferDto,
} from '@bd/api/common/types/purchase-offers'

export const actions: ActionTree<AgentPurchaseState, AppState> = {
  async lastPurchaseOffer(
    { commit },
    payload: { offerId: number; buyerId: number },
  ) {
    try {
      const { data } = await commonApi.purchaseOfferAgent(payload)
      commit('SET_PURCHASE', data)
    } catch (error) {
      logErr(error)
    }
  },
  async purchaseOffers({ commit }, offerId: number) {
    try {
      const { data } = await commonApi.purchaseOfferList(offerId)
      commit('SET_PURCHASE_LIST', data)
    } catch (error) {
      logErr(error)
    }
  },
  async addPurchase({ commit }, payload: PurchaseOfferPayload) {
    commit('CLEAR_ERROR')
    try {
      await commonApi.addPurchase(payload)
    } catch (error) {
      commit('SET_ERROR', error)
      logErr(error)
    }
  },

  async acceptLastPurchase(_, payload: AddAgentPurchaseOfferDto) {
    try {
      await commonApi.acceptPurchase(payload)
    } catch (error) {
      logErr(error)
    }
  },
}
