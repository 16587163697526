import { ActionTree } from 'vuex'
import { AppState, ClientState } from '@bd/agent/types'
import { logErr } from '@bd/helpers'
import { agentApi } from '@bd/api/agent-api'
import { ClientParams, commonApi } from '@bd/api'
import { DEFAULT_CLIENT_QUERY_PARAMS } from '@bd/agent/config'
import { deleteNote } from '@bd/api/common/clients-management-api'

export const actions: ActionTree<ClientState, AppState> = {
  async setFilterAndRefresh({ commit }, payload: ClientParams) {
    commit('SET_FILTERS', payload)
  },
  async setFilters({ commit }, payload: ClientParams) {
    commit('SET_FILTERS', payload)
  },
  async addClient({ commit }, payload) {
    commit('SET_LOADING', true, { root: true })
    try {
      await agentApi.addClient(payload)
    } catch (error) {
      logErr(error)
      throw error
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },
  async clientDetails({ commit }, userId) {
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await agentApi.clientDetails(userId)
      commit('SET_CLIENT', data)
    } catch (error) {
      logErr(error)
      throw error
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },
  async removeNote({ commit }, noteId) {
    commit('SET_LOADING', true, { root: true })
    try {
      await deleteNote(noteId)
    } catch (error) {
      logErr(error)
      throw error
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },
  async clientByQuery({ commit }, query: string) {
    const params = {
      ...DEFAULT_CLIENT_QUERY_PARAMS,
      query,
    }
    commit('SET_CLIENTS_BY_QUERY_LOADING', true)
    try {
      const { data } = await commonApi.getMeetingAttendeesByQuery(params)
      commit('SET_CLIENTS_BY_QUERY', data)
    } catch (error) {
      logErr(error)
      throw error
    } finally {
      commit('SET_CLIENTS_BY_QUERY_LOADING', false)
    }
  },
}
