import { AgentOfferState } from '@bd/agent/types'

export const isLoadingChunk = (state?: AgentOfferState): boolean => {
  const isContentPresentBeforehand = !!state?.citiesAndProvinces.content
  return (
    (state?.citiesAndProvinces.isLoading ?? false) && isContentPresentBeforehand
  )
}

export const isLoadingFirstTime = (state?: AgentOfferState): boolean => {
  const isContentPresentBeforehand = !!state?.citiesAndProvinces.content
  return (
    (state?.citiesAndProvinces.isLoading ?? false) &&
    !isContentPresentBeforehand
  )
}

export const allCitiesLoaded = (state?: AgentOfferState): boolean => {
  const citiesContent = state?.citiesAndProvinces.content
  if (!citiesContent) {
    return false
  }
  return citiesContent.content.length >= citiesContent.totalCount
}

export const getNextPageIndex = (state?: AgentOfferState) => {
  const citiesContent = state?.citiesAndProvinces.content
  return citiesContent != null ? citiesContent.pageNumber + 1 : 0
}
