
import { computed, defineComponent } from 'vue'
import { CounterProposal, ProposalMessages } from '@bd/components'
import ProposalBuyerSearch from '@bd/agent/components/ProposalBuyerSearch.vue'
import { useAppStore } from '@bd/agent/store'
import {
  PurchaseOfferPayload,
  AddAgentPurchaseOfferDto,
} from '@bd/api/common/types/purchase-offers'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {
    CounterProposal,
    ProposalBuyerSearch,
  },
  setup() {
    const store = useAppStore()
    const toast = useToast()
    const errorData = computed(() => store.state.purchaseOffers?.error)
    const proposalMessage = computed(
      () => store.state.purchaseOffers?.purchaseMessage,
    )
    const lastOffer = computed(() => store.state?.purchaseOffers?.purchaseOffer)

    const acceptOffer = async (payload: AddAgentPurchaseOfferDto) => {
      await store.dispatch('purchaseOffers/acceptLastPurchase', payload)

      toast.add(ProposalMessages.proposalApproved)

      store.dispatch('dialogVisibility', {
        counterProposalModal: false,
      })
    }

    const isProposalModalVisible = computed({
      get: () => store.state.dialog.proposalModal,
      set: (value: boolean) => {
        store.dispatch('dialogVisibility', {
          proposalModal: value,
        })
      },
    })
    const isCounterProposalModalVisible = computed({
      get: () => store.state.dialog.counterProposalModal,
      set: (value: boolean) => {
        store.dispatch('dialogVisibility', {
          counterProposalModal: value,
        })
      },
    })

    const hide = (name: string) => {
      store.dispatch('dialogVisibility', {
        [name]: false,
      })
    }

    const makeProposal = async (payload: PurchaseOfferPayload) => {
      if (!payload.buyerId || !payload.offerId || !payload.price) {
        return
      }
      await store.dispatch('purchaseOffers/addPurchase', payload)

      if (!errorData.value) {
        store.dispatch('dialogVisibility', {
          counterProposalModal: false,
        })
      }
      toast.add(
        !errorData.value
          ? ProposalMessages.counterproposalSuccess
          : ProposalMessages.counterproposalError,
      )
    }

    const clearErrorData = () => {
      store.commit('purchaseOffers/CLEAR_ERROR')
    }

    const clearProposalData = () => {
      hide('counterProposalModal')
      store.commit('purchaseOffers/CLEAR_ERROR')
      store.commit('purchaseOffers/CLEAR_LAST_PURCHASE')
      store.commit('purchaseOffers/CLEAR_PURCHASE_MESSAGE')
    }

    return {
      acceptOffer,
      isProposalModalVisible,
      isCounterProposalModalVisible,
      lastOffer,
      hide,
      makeProposal,
      errorData,
      clearErrorData,
      proposalMessage,
      clearProposalData,
    }
  },
})
