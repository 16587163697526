import { default as common } from '@bd/agent/config/common.json'
import { ClientState } from '@bd/agent/types'
import { emptyLoadableResource } from '@bd/helpers'

export const state: ClientState = {
  clients: {
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    content: [],
  },
  client: undefined,
  filters: {
    sort: common.defaultClientsSort,
  },
  clientsByQuery: emptyLoadableResource(),
}
