
import { defineComponent, shallowRef, watch } from 'vue'
import { useDebounce } from '@vueuse/shared'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { subscribe } from '@bd/helpers'
import { DashboardAutocomplete, UserAvatar, Svg } from '@bd/components'
import { QuickSearchRowDto, QuickSearchOfferDto, agentApi } from '@bd/api'

interface Row {
  client: QuickSearchRowDto['client']
  offer?: QuickSearchOfferDto
}

const getRows = (row: QuickSearchRowDto): Row | Row[] => {
  const offer = row.offers?.find((o) => o.title && o.description)
  return { client: row.client, offer }
}

export default defineComponent({
  components: {
    DashboardAutocomplete,
    UserAvatar,
    Svg,
  },
  setup() {
    const minQueryLength = 3
    const router = useRouter()
    const queryString = shallowRef('')
    const debouncedQuery = useDebounce(queryString, 200)
    const items = shallowRef<Row[]>([])
    const isLoading = shallowRef(false)

    watch(debouncedQuery, (query, _prevQuery, onCleanup) => {
      if (queryString.value.length < minQueryLength) {
        items.value = []
      } else {
        isLoading.value = true
        const promise = agentApi.quickSearch(query)
        const cancel = subscribe(promise, (res) => {
          items.value = res.data.content.flatMap(getRows)
          isLoading.value = false
        })
        onCleanup(() => {
          cancel()
          isLoading.value = false
        })
      }
    })

    const onRowSelected = (row: Row) => {
      if (row.offer) {
        router.push({
          name: 'DashboardOffer',
          params: { id: row.offer.id },
        })
      } else {
        router.push({
          name: 'ClientDetails',
          params: { id: row.client.id },
        })
      }
    }

    return {
      ...useI18n(),
      queryString,
      items,
      onRowSelected,
      isLoading,
      minQueryLength,
      getRowId: (row: Row) => `${row.client.id} ${row.offer?.id}`,
    }
  },
})
