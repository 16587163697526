import { ClientParams } from '@bd/api'

export const addOrRemoveClientsFilter = (
  filters: ClientParams,
  payload: ClientParams,
) => {
  const prop = Object.keys(payload)[0] as keyof ClientParams
  const value = Object.values(payload)[0] as string | number
  const singleValues = ['agentId'] as const
  const textValues = ['name'] as const

  if (payload.pageIndex !== undefined) {
    filters.pageIndex = payload.pageIndex
  }
  if (payload.showAll !== undefined) {
    filters.showAll = payload.showAll
  }

  const isOneOf = <T extends readonly string[]>(
    prop: string,
    opts: T,
  ): prop is T[number] => opts.includes(prop)

  if (isOneOf(prop, singleValues)) {
    if (filters[prop] === value) {
      const { [prop]: value, ...rest } = { ...filters }
      return rest
    } else {
      const updatedFilter = { ...filters, [prop]: value }
      return updatedFilter
    }
  }

  if (isOneOf(prop, textValues)) {
    if (String(value).length) {
      const updatedFilter = { ...filters, [prop]: value }
      return updatedFilter
    } else {
      const { [prop]: value, ...rest } = { ...filters }
      return rest
    }
  }
  return filters
}
