import { MutationTree } from 'vuex'
import { ClientState } from '@bd/agent/types'
import { addOrRemoveClientsFilter } from '@bd/agent/services/mutation.service'
import { ClientParams, ClientDetailsDto, ResultPageDto } from '@bd/api'
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'

export const mutations: MutationTree<ClientState> = {
  SET_CLIENT(state, payload: ClientDetailsDto) {
    state.client = payload
  },
  SET_FILTERS(state, payload: ClientParams) {
    const filters = { ...state.filters }
    const result = addOrRemoveClientsFilter(filters, payload)
    if (result) {
      state.filters = result
    }
  },
  SET_CLIENTS_BY_QUERY(state, payload: ResultPageDto<MeetingAttendeeDto>) {
    state.clientsByQuery.content = payload
  },
  SET_CLIENTS_BY_QUERY_LOADING(state, isLoading: boolean) {
    state.clientsByQuery.isLoading = isLoading
  },
}
