import { UserRole } from '@bd/api'
import { UserType } from '@bd/components'
import { computed, Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export interface UserDescriptor {
  userRole?: UserRole[]
  roles?: UserRole[]
  developer?: boolean
  sellerDeveloper?: boolean
}

export const useUserType = (data: Ref<UserDescriptor>) =>
  computed<UserType>(() => {
    const userRole = data.value.userRole?.[0] || data.value.roles?.[0]
    if (userRole === UserRole.CUSTOMER) {
      return UserType.Buyer
    }
    if (
      userRole === UserRole.SELLER &&
      (data.value.developer || data.value.sellerDeveloper)
    ) {
      return UserType.Developer
    }
    if (userRole === UserRole.CALL_CENTER) {
      return UserType.CallCenter
    }
    const roleName =
      userRole!.charAt(0).toUpperCase() + userRole!.toLowerCase().slice(1)

    return UserType[roleName as keyof typeof UserType]
  })

export const getUserRoleLabel = (
  userRole: UserRole | undefined,
  developer?: boolean,
) => {
  const { t } = useI18n()

  if (userRole === UserRole.CUSTOMER) {
    return t('userType.buyer')
  }
  if (userRole === UserRole.SELLER && developer) {
    return t('clients.developer')
  }

  return t(`enums.userRole.${userRole}`)
}

export const useUserRoleLabel = (data: Ref<UserDescriptor>) =>
  computed(() =>
    getUserRoleLabel(
      data.value.userRole?.[0] || data.value.roles?.[0],
      data.value.developer || data.value.sellerDeveloper,
    ),
  )
