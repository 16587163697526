import { Module } from 'vuex'
import { AppState, ClientState } from '@bd/agent/types'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

const clients: Module<ClientState, AppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default clients
