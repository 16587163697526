import { Module } from 'vuex'
import { CommonAppState } from '@bd/store-modules/types'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { CommonCalendarEventState } from '@bd/store-modules/types/calendarEvent'

const calendarEvent: Module<CommonCalendarEventState, CommonAppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default calendarEvent
