<template>
  <DashboardAutocomplete
    @input="queryString = $event"
    :query="queryString"
    :items="items"
    :getItemKey="getRowId"
    :isLoading="isLoading"
    :minQueryLength="minQueryLength"
    :placeholder="t('search')"
    :showSearchButton="false"
    @item-select="onRowSelected"
  >
    <template #item="{ item, select, focused, refElem }">
      <div
        :ref="refElem"
        @click="select(item)"
        class="item"
        :class="{
          'item--offer': item.offer,
          'item--client': !item.offer,
          'is-active': focused,
        }"
      >
        <template v-if="item.offer">
          <Svg
            class="item-avatar c-zest"
            :src="require('@bd/assets/icons/market.svg')"
          />
          <div class="item-content">
            <div class="item-row item-row--main">
              {{ item.offer.title }}
              <span class="text--muted">
                ({{ item.offer.displayableId }})
              </span>
            </div>
            <div class="item-row text--muted item-row--multi">
              {{ item.offer.description }}
            </div>
            <div class="item-row text--muted">
              {{ item.client.firstName }}
              {{ item.client.lastName }}
              ({{ item.client.displayableId }})
            </div>
            <div class="item-row item-row--icon text--muted">
              <img src="@bd/assets/icons/phone.svg" />
              {{ item.client.phoneNumber }}
            </div>
            <div class="item-row item-row--icon text--muted">
              <img src="@bd/assets/icons/mail.svg" />
              {{ item.client.email }}
            </div>
          </div>
        </template>

        <template v-else>
          <UserAvatar
            class="item-avatar"
            userType="Buyer"
            :user="item.client"
          />
          <div class="item-content">
            <div class="item-row item-row--main">
              {{ item.client.firstName }}
              {{ item.client.lastName }}
              <span class="text--muted">
                ({{ item.client.displayableId }})
              </span>
            </div>
            <div class="item-row item-row--icon text--muted">
              <img src="@bd/assets/icons/phone.svg" />
              {{ item.client.phoneNumber }}
            </div>
            <div class="item-row item-row--icon text--muted">
              <img src="@bd/assets/icons/mail.svg" />
              {{ item.client.email }}
            </div>
          </div>
        </template>
      </div>
    </template>
  </DashboardAutocomplete>
</template>

<script lang="ts">
import { defineComponent, shallowRef, watch } from 'vue'
import { useDebounce } from '@vueuse/shared'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { subscribe } from '@bd/helpers'
import { DashboardAutocomplete, UserAvatar, Svg } from '@bd/components'
import { QuickSearchRowDto, QuickSearchOfferDto, agentApi } from '@bd/api'

interface Row {
  client: QuickSearchRowDto['client']
  offer?: QuickSearchOfferDto
}

const getRows = (row: QuickSearchRowDto): Row | Row[] => {
  const offer = row.offers?.find((o) => o.title && o.description)
  return { client: row.client, offer }
}

export default defineComponent({
  components: {
    DashboardAutocomplete,
    UserAvatar,
    Svg,
  },
  setup() {
    const minQueryLength = 3
    const router = useRouter()
    const queryString = shallowRef('')
    const debouncedQuery = useDebounce(queryString, 200)
    const items = shallowRef<Row[]>([])
    const isLoading = shallowRef(false)

    watch(debouncedQuery, (query, _prevQuery, onCleanup) => {
      if (queryString.value.length < minQueryLength) {
        items.value = []
      } else {
        isLoading.value = true
        const promise = agentApi.quickSearch(query)
        const cancel = subscribe(promise, (res) => {
          items.value = res.data.content.flatMap(getRows)
          isLoading.value = false
        })
        onCleanup(() => {
          cancel()
          isLoading.value = false
        })
      }
    })

    const onRowSelected = (row: Row) => {
      if (row.offer) {
        router.push({
          name: 'DashboardOffer',
          params: { id: row.offer.id },
        })
      } else {
        router.push({
          name: 'ClientDetails',
          params: { id: row.client.id },
        })
      }
    }

    return {
      ...useI18n(),
      queryString,
      items,
      onRowSelected,
      isLoading,
      minQueryLength,
      getRowId: (row: Row) => `${row.client.id} ${row.offer?.id}`,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.autocomplete-results) {
  @include breakpoint-up(md) {
    min-width: 400px;
  }
}

.item {
  overflow: hidden;
  cursor: pointer;
  padding: 1rem 0;
  border-bottom: $divider-border;

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }

  @include breakpoint-up(md) {
    &:first-child {
      padding-top: 0;
    }
  }
}

.item-avatar {
  float: left;
  @include uniform-size(50px);
  margin-right: 0.75rem;
  font-size: 18px;
}

.item-content {
  overflow: hidden;
}

.item-row {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.75rem;
}

.text--muted {
  color: $manatee;
  font-weight: 500;
  line-height: 1.33;
}

.item-row--main {
  white-space: unset;
  @include line-clamp(2);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.2em;
  color: var(--port-gore);

  .text--muted {
    font-size: 0.75rem;
  }
}

.item-row--multi {
  white-space: unset;
  @include line-clamp(3);
  margin: 4px 0;
}

.item-row--icon {
  @include flex($justify: flex-start);
  gap: 4px;
  line-height: 1.4;
}

.item.is-active,
.item:hover {
  .item-row--main {
    color: $primary-color;
  }
}
</style>
