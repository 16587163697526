
import { useAppStore } from '@bd/agent/store'
import {
  BottomNavigation,
  NotificationsHost,
  OfflineScreen,
  TopNavigation,
  useBreakpoints,
  useNavigationBadges,
  usePurchaseOffers,
  useToastConfig,
} from '@bd/components'
import { useOnline } from '@vueuse/core'
import Toast from 'primevue/toast'
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { buildNavigation } from '../config'
import ProposalModalHost from './ProposalModalHost.vue'
import QuickSearch from './QuickSearch/QuickSearch.vue'

export default defineComponent({
  name: 'AppLayout',
  components: {
    TopNavigation,
    BottomNavigation,
    Toast,
    NotificationsHost,
    ProposalModalHost,
    QuickSearch,
    OfflineScreen,
  },
  setup() {
    const store = useAppStore()
    const route = useRoute()
    const router = useRouter()
    const { mobileSize } = useBreakpoints()
    const { toastPosition, toastZIndex } = useToastConfig()

    const navigationItems = computed(() =>
      buildNavigation(store, router, mobileSize),
    )
    const showBottomNavigation = computed(() => {
      return store.state.bottomNavigationVisible
    })

    const isNotificationsRoute = computed(() => route.name === 'Notifications')

    const online = useOnline()

    useNavigationBadges()
    usePurchaseOffers()

    return {
      navigationItems,
      showBottomNavigation,
      toastPosition,
      toastZIndex,
      isNotificationsRoute,
      online,
    }
  },
})
