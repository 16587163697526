import { ResultPageDto, TimeSlot } from '@bd/api'
import {
  MeetingAttendeeDto,
  MeetingOfferDto,
} from '@bd/api/common/types/calendar-event'
import { emptyLoadableResource, LocalIsoDate } from '@bd/helpers'
import {
  CalendarEventAttendee,
  CalendarEventMeetingType,
  CalendarEventOffer,
  CommonCalendarEventState,
  EventBuyerSelectPayload,
  EventDateSelectPayload,
  OfferSelectionTab,
} from '@bd/store-modules/types/calendarEvent'
import { MutationTree } from 'vuex'
import { EVENT_SAVE_DATA_INITIAL_STATE } from './utils'

export const mutations: MutationTree<CommonCalendarEventState> = {
  SET_OFFER_SELECTED_TAB(state, selectedTab: OfferSelectionTab) {
    state.eventSaveMetaData.selectedOffersTab = selectedTab
  },
  SET_OFFERS_SEARCH_PHRASE(state, searchPhrase: string) {
    state.eventSaveMetaData.offersSearchPhrase = searchPhrase
  },
  SET_ATTENDEES_SEARCH_PHRASE(state, searchPhrase: string) {
    state.eventSaveMetaData.attendeesSearchPhrase = searchPhrase
  },
  SET_MEETING_OFFERS_LOADING(state, isLoading: boolean) {
    state.eventSaveMetaData.offers.isLoading = isLoading
  },
  SET_MEETING_OFFERS(state, payload: ResultPageDto<MeetingOfferDto>) {
    state.eventSaveMetaData.offers.content = payload
  },
  SET_MEETING_ATTENDEES_LOADING(state, isLoading: boolean) {
    state.eventSaveMetaData.attendees.isLoading = isLoading
  },
  SET_MEETING_ATTENDEES(state, payload: ResultPageDto<MeetingAttendeeDto>) {
    state.eventSaveMetaData.attendees.content = payload
  },
  CLEAR_EVENT_SAVE_DATA(state) {
    const previousBuyer = state.eventSaveData.buyer
    const previousDate = state.eventSaveData.date
    state.eventSaveData = { ...EVENT_SAVE_DATA_INITIAL_STATE }
    if (state.eventSaveMetaData.keepBuyer) {
      state.eventSaveData.buyer = previousBuyer
    }
    if (state.eventSaveMetaData.keepDate) {
      state.eventSaveData.date = previousDate
    }
    state.eventSaveMetaData.offersSearchPhrase = ''
    state.eventSaveMetaData.attendeesSearchPhrase = ''
    state.eventSaveMetaData.availableTimeSlots = emptyLoadableResource([])
    state.eventSaveMetaData.keepBuyer = false
    state.eventSaveMetaData.keepDate = false
  },
  SET_SELECTED_OFFER(state, payload: CalendarEventOffer) {
    state.eventSaveData.offer = payload
  },
  SET_SELECTED_SELLER(state, payload: CalendarEventAttendee) {
    state.eventSaveData.seller = payload
  },
  SET_SELECTED_BUYER(state, payload: EventBuyerSelectPayload) {
    state.eventSaveData.buyer = payload.buyer
    state.eventSaveMetaData.keepBuyer = payload.keepBuyer
  },
  SET_SELECTED_AGENT_ID(state, payload: number) {
    state.eventSaveData.agentId = payload
  },
  SET_SELECTED_MEETING_TYPE(state, payload: CalendarEventMeetingType) {
    state.eventSaveData.meetingType = payload
  },
  SET_SELECTED_DATE(state, payload: EventDateSelectPayload) {
    state.eventSaveData.date = payload.date
    state.eventSaveMetaData.keepDate = payload.keepDate
  },
  SET_SELECTED_TIME_SLOTS(state, payload: TimeSlot.TimeSlotID[]) {
    state.eventSaveData.timeSlotIds = payload
  },
  SET_AVAILABLE_TIME_SLOTS_LOADING(state, isLoading: boolean) {
    state.eventSaveMetaData.availableTimeSlots.isLoading = isLoading
  },
  SET_AVAILABLE_TIME_SLOTS(state, payload: TimeSlot.TimeSlotDto[]) {
    state.eventSaveMetaData.availableTimeSlots.content = payload
  },
  SET_USER_CALENDAR_EVENT_ID(state, id: number) {
    state.eventSaveData.eventId = id
  },
  SET_DISABLED_TIME_SLOTS(state, timeSlots: TimeSlot.TimeSlotID[]) {
    state.eventSaveMetaData.disabledTimeSlots = timeSlots
  }
}
