import { AgentAvailabilityState, AppState } from '@bd/agent/types'
import {
  AddAgentAvailabilityExceptionDto,
  agentApi,
  SaveAgentAvailabilityRuleDto,
} from '@bd/api'
import { LocalIsoDate, logErr } from '@bd/helpers'
import { ActionTree } from 'vuex'

export const actions: ActionTree<AgentAvailabilityState, AppState> = {
  async availabilityRules({ commit }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await agentApi.getAvailabilityRules()
      commit('SET_AVAILABILITY_RULES', data)
    } catch (err) {
      logErr(err)
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },

  async addAvailability(_, payload: SaveAgentAvailabilityRuleDto) {
    try {
      await agentApi.addAgentAvailability(payload)
    } catch (err) {
      logErr(err)
      throw err
    }
  },

  async editAvailability(_, payload: SaveAgentAvailabilityRuleDto) {
    try {
      await agentApi.editAgentAvailability(payload)
    } catch (err) {
      logErr(err)
      throw err
    }
  },

  async availabilityException({ commit }, payload: LocalIsoDate) {
    commit('SET_AVAILABILITY_EXCEPTION_LOADING', true)
    try {
      const { data } = await agentApi.getAvailabilityException(payload)
      commit('SET_AVAILABILITY_EXCEPTION', data)
    } catch (err) {
      logErr(err)
    } finally {
      commit('SET_AVAILABILITY_EXCEPTION_LOADING', false)
    }
  },

  async saveAvailabilityException(
    _,
    payload: AddAgentAvailabilityExceptionDto,
  ) {
    try {
      await agentApi.saveAvailabilityException(payload)
    } catch (err) {
      logErr(err)
      throw err
    }
  },
}
