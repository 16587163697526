import {
  AddAgentAvailabilityExceptionDto,
  AgentAvailabilityExceptionDto,
  OfferStatisticsSummaryDto,
  OfferStatisticsParams,
  OfferStatisticsDto,
  QuickSearchRowDto,
} from './types/index'
import {
  ApiResponse,
  http,
  ResultListDto,
  UserCalendarEventDto,
} from '@bd/api/common'
import {
  UserCalendarEventDetailsDto,
  ClientParams,
  ClientDto,
  AddCustomerDto,
  ClientDetailsDto,
  ResultSliceDto,
  ClientWithPreferencesDto,
  ClientPreferencesParams,
  ResultPageDto,
} from '@bd/api'
import { OfferDetailsDto, AgentOffersParams } from '@bd/store-modules/types'
import { stringify } from 'qs'
import {
  AgentOfferRowDto,
  AgentAvailabilityRuleDto,
  SaveAgentAvailabilityRuleDto,
} from './types'
import { LocalIsoDate } from '@bd/helpers'

export const offer = (id: number): ApiResponse<OfferDetailsDto> => {
  return http.get(`/offer/${id}`)
}

export const userCalendarEvents = (
  date: LocalIsoDate,
): ApiResponse<UserCalendarEventDto[]> => {
  return http.get(`/user-calendar-events`, {
    params: {
      date,
    },
  })
}

export const userCalendarEventDetails = (
  eventId: UserCalendarEventDto['userCalendarEventId'],
): ApiResponse<UserCalendarEventDetailsDto> => {
  return http.get(`/user-calendar-event/${eventId}`)
}

export const offers = (
  params: AgentOffersParams,
): ApiResponse<ResultPageDto<AgentOfferRowDto>> => {
  return http.get('/offers', {
    params,
    paramsSerializer: (params) => {
      return stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

const agentAvailabilityEndpoint = '/agent-availability-rules'
const agentAvailabilityExceptionEndpoint = '/agent-availability-exception'

export const getAvailabilityRules = (): ApiResponse<
  AgentAvailabilityRuleDto[]
> => {
  return http.get(agentAvailabilityEndpoint)
}

export const addAgentAvailability = (
  payload: SaveAgentAvailabilityRuleDto,
): ApiResponse<void> => {
  return http.post(agentAvailabilityEndpoint, payload)
}

export const editAgentAvailability = (
  payload: SaveAgentAvailabilityRuleDto,
): ApiResponse<void> => {
  return http.put(agentAvailabilityEndpoint, payload)
}

export const getAvailabilityException = (
  date: LocalIsoDate,
): ApiResponse<AgentAvailabilityExceptionDto> => {
  return http.get(agentAvailabilityExceptionEndpoint, { params: { date } })
}

export const saveAvailabilityException = (
  payload: AddAgentAvailabilityExceptionDto,
): ApiResponse<void> => {
  return http.post(agentAvailabilityExceptionEndpoint, payload)
}

export const clients = (
  params: ClientParams,
): ApiResponse<ResultListDto<ClientDto>> => {
  return http.get('/client', {
    params,
    paramsSerializer: (params) => {
      return stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const addClient = (
  client: AddCustomerDto,
): ApiResponse<ClientDetailsDto> => {
  return http.post('/client', client)
}

export const clientWithPreferences = (
  params: ClientPreferencesParams,
): ApiResponse<ResultSliceDto<ClientWithPreferencesDto>> => {
  return http.get('/client-with-preferences', {
    params,
    paramsSerializer: (params) => {
      return stringify(params, { arrayFormat: 'repeat' })
    },
  })
}
export const clientDetails = async (userId: number) => {
  const res = await http.get<ClientDetailsDto>(`/client/${userId}`)
  res.data.notes.sort((a, b) => b.createdAt - a.createdAt)
  return res
}

export const offerStatistics = (
  params: OfferStatisticsParams,
): ApiResponse<OfferStatisticsDto> => {
  return http.get('/offer-statistics', { params })
}

export const offerStatisticsSummary = (
  offerId: OfferDetailsDto['id'],
): ApiResponse<OfferStatisticsSummaryDto> => {
  return http.get('/offer-statistics-summary', { params: { offerId } })
}

export const quickSearch = (
  query: string,
  size = 10,
): ApiResponse<ResultPageDto<QuickSearchRowDto>> => {
  return http.get('/quick-search', { params: { query, size } })
}
