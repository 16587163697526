import { AgentAvailabilityState } from '@bd/agent/types'
import {
  AgentAvailabilityExceptionDto,
  AgentAvailabilityRuleDto,
} from '@bd/api'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<AgentAvailabilityState> = {
  SET_AVAILABILITY_RULES(state, payload: AgentAvailabilityRuleDto[]) {
    state.availabilityRules = payload
  },
  SET_AVAILABILITY_EXCEPTION_LOADING(state, payload: boolean) {
    state.availabilityException.isLoading = payload
  },
  SET_AVAILABILITY_EXCEPTION(state, payload: AgentAvailabilityExceptionDto) {
    state.availabilityException.content = payload
  },
}
